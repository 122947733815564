import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.page.html',
  styleUrls: ['./invoice.page.scss'],
})
export class InvoicePage implements OnInit {
  title: string;
  invoice: any = {};

  constructor(
    private _route: ActivatedRoute,
    private _location: Location) { }

  ngOnInit() {
    this.invoice.id = this._route.snapshot.params.id;
    this.title = `Invoice #${this.invoice.id}`;
  }

  print() {
    window.print();
  }

  goback() {
    this._location.back();
  }

}
