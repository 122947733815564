import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  api = `${environment.api.endpoint}`;

  private _featuredLocation = 1;

  constructor(
    private http: HttpClient) { }

  getProduct(id: number): Promise<any> {
    return this.http.get(`${this.api}/products/${id}`)
      .toPromise();
  }

  set featuredLocation(location: number) {
    this._featuredLocation = location;
  }

  get featuredProducts(): Promise<any> {
    const f = JSON.stringify({
      where: { location: this._featuredLocation },
      include: 'product'
    });

    return this.http.get(`${this.api}/FeaturedProducts?filter=${f}`)
      .toPromise();
  }
}
