import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private _message = new Subject();

  message = this._message.asObservable();

  constructor(private _loadingCtl: LoadingController) { }

  update(msg: string) {
    this._message.next(msg);
  }

  clear() {
    this._message.next(null);
  }

  dismiss() {
    this._loadingCtl.dismiss();
  }

  async show(msg: string) {
    const _loading = await this._loadingCtl.create({
      message: msg
    });

    _loading.onDidDismiss()
      .then(() => this.clear());

    return await _loading.present();
  }
}
