import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { StorageService } from './storage.service';
import { map } from 'rxjs/operators';
import { NotifyService } from '../@core/notify.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  _id: number;
  _token: any;
  _year = 2024;
  _type = 'student';
  shipping: any;
  api = `${environment.api.endpoint}`;

  constructor(
    private _http: HttpClient,
    private _storageService: StorageService,
    private _notifyService: NotifyService) {}

  set token(token: any) {
    this._token = token;
  }

  set id(id: number) {
    this._id = id;
  }

  set year(year: number) {
    this._year = year;
  }

  set type(type: string) {
    this._type = type;
  }

  get order() {
    if (this._type === 'student') {
      return this._http.get(`${this.api}/StudentOrders/get?id=${this._id}`)
        .toPromise();
    } else if (this._type === 'store') {
      return this._http.get(`${this.api}/StoreOrders/get?id=${this._id}`)
        .toPromise();
    }
  }

  get orders() {
    if (this._type === 'student') {
      return this._http.get(`${this.api}/StudentOrders/byCustomer`)
        .toPromise();
    } else if (this._type === 'store') {
      return this._http.get(`${this.api}/StoreOrders/byCustomer`)
        .toPromise();
    }
  }

  async createOrder(_data: any): Promise<any> {
    this.shipping = this._storageService.checkoutShipping;

    _data.token = this._token;
    _data.delivery = this.shipping.delivery.toLowerCase();
    _data.blackout = {
      from: this.shipping.blackoutFrom,
      to: this.shipping.blackoutTo
    };

    _data.payment_method = 'stripe';
    _data.order.entry = 'mobile';

    _data.order.subtotal = +(_data.order.subtotal).toFixed(2);
    _data.order.total = +(_data.order.total).toFixed(2);

    // console.log('Creating Order:', _data);
    // return;

    const order = await this._http.post(`${this.api}/orders/add`, { data: _data })
      .pipe(map((res: any) => {
        return res.response;
      }))
      .toPromise()
      .catch(err => {
        console.error(err);
        this._notifyService.update(`${err.statusText}: ${err.error.error.message}`, 'danger', 10000);
      });

    return order;
  }
}
